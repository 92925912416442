<div class="top_sec partners_top">
  <div class="contentCenter">
    <div class="top_sec_box" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <h1 class="top_sec_box_h" fxFlex="100" i18n="partners|top header">FOR PARTNERS</h1>

    </div>

  </div>

  <div class="anm_figure fgr1 fgr_position1" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.025, initialValue: 56, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr2 fgr_position2" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.042, initialValue: 31, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position3" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 87, cssUnit:'%'}]"></div>

<!--
  <div class="anm_figure fgr2 fgr_position4" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.03, initialValue: 200, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr1 fgr_position5" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.025, initialValue: 242, cssUnit:'%'}]"></div>
  <div class="anm_figure fgr3 fgr_position6" inViewport appParalax [config]="[{cssProperty: 'top', ratio: 0.02, initialValue: 256, cssUnit:'%'}]"></div>
-->

</div>



<div class="partners_sec1">
  <div class="contentCenter">
    <div class="block_img img_left">

        <img src="/assets/partners/partners_ib.webp" srcset="/assets/partners/partners_ib@2x.webp 2x" i18n-alt="partners|" alt="Marketing & IBs">

    </div>
    <div class="m_t_mid p_z_cont" fxLayout="row wrap" fxLayoutAlign="start start">
      <div class="block_txt">
        <h2 class="block_txt_h" i18n="partners|section 1 header 1">Marketing & IBs </h2>
        <p class="m_t_mid"><strong i18n="partners|section 1 txt 1">Whether an organization or an individual, it is an excellent opportunity to get substantial ongoing commission and create new revenue streams by introducing and referring clients to Swiss International Financial Services.</strong></p>
        <p class="m_t_mob" i18n="partners|section 1 txt 2">We highly value the business that you introduce and we generously reward it with competitive IB commission structure and compensation packages.</p>
        <p class="m_t_mob" i18n="partners|section 1 txt 3">We seek to ensure true partnership through mutual support and cooperation, as we believe that we all prosper together and share profits. Our back office systems allow IBs to receive full reporting on client account activity. We have what introducing brokers need to serve their clients.</p>
        <p class="m_t_mob" i18n="partners|section 1 txt 4">Being an IB with Swiss International Financial Services is open to everyone! To join, simply contact us at admin@swissfs.com or click on the button below and become an IB to Swiss FS.</p>

        <a href="{{pcUrl}}/registrationIb.html" target="_blank" rel="nofollow noreferrer" i18n-aria-label="partners button 1|@@partners_btn1" aria-label="BECOME AN IB" class="btn btn_mob btn_red m_t_mid" i18n="@@partners_btn1">BECOME AN IB</a>
      </div>
    </div>

    <!---->

    <!--<div class="m_t_pc_r block_img img_right">

        <img src="/assets/partners/partners_wl.webp" srcset="/assets/partners/partners_wl@2x.webp 2x" i18n-alt="partners|partners img 1" alt="White Label">

    </div>-->

    <!--<div class="m_t_mid" fxLayout="row wrap" fxLayoutAlign="end start">
      <div class="block_txt">
        <h2 class="block_txt_h" i18n="partners|section 1 header 2">White Label</h2>
        <p class="m_t_mid"><strong i18n="partners|section 1 txt 5">For White Label partners who are looking to grow their business and widen their product offering, Swiss International Financial Services provides tailored packages that can be offered to their own client base. Our platforms are fully customizable and can be re-branded.</strong></p>
        <p class="m_t_mob" i18n="partners|section 1 txt 6">You can start your own online business and run your own independent brokerage with extensive continuous support from us.</p>
        <p class="m_t_mob" i18n="partners|section 1 txt 7">Through our white label services, you will enjoy the following benefits and associated services:</p>

        <div class="m_t_mid" fxLayout="row wrap" fxLayoutAlign="space-between start">
          <ul class="partners_sec1_fMobM list_swfs" fxFlex="100" fxFlex.gt-sm="48">
            <li i18n="partners|section 1 list 1">Take client exposure</li>
            <li i18n="partners|section 1 list 2">Set up accounts yourself</li>
            <li i18n="partners|section 1 list 3">Set your own liquidation levels</li>
            <li i18n="partners|section 1 list 4">Increase or decrease leverage</li>
          </ul>

          <ul class="list_swfs" fxFlex="100" fxFlex.gt-sm="48">
            <li i18n="partners|section 1 list 5">Allow your clients to hedge</li>
            <li i18n="partners|section 1 list 6">Supply VIP clients with credit</li>
            <li i18n="partners|section 1 list 7">STP any unwanted exposure</li>
            <li i18n="partners|section 1 list 8">Charge different levels of commissions</li>
          </ul>
        </div>
        <a href="{{pcUrl}}/registrationIb.html" target="_blank" rel="nofollow noreferrer" i18n-aria-label="partners button 2|@@partners_btn2" aria-label="Start your own Brokerage Company" class="btn btn_mob btn_red m_t_mid" i18n="@@partners_btn2">Start your own Brokerage Company</a>

      </div>
    </div>-->

  </div>
</div>
